export default function Home({ Props }) {
  return (
    <>
      <div className="text-center text-secondaryDark dark:text-gray-200">
        <h1 className="text-5xl mt-12 font-bold">Welcome to Koala Write!</h1>
        <h2 className="my-3">A simple collaborative writing tool 🐾</h2>
      </div>
      <div className="text-secondaryDark dark:text-gray-200">
        <h1>🧪 Recipes</h1>
        <div className="font-bold">
          Goal: Get a classroom to write an assignment live.
        </div>
        <ol>
          <li>Create a party</li>
          <li>Invite your students</li>
          <li>Write and see all of their writing live!</li>
        </ol>
        <div className="font-bold">Goal: Write a diary.</div>
        <ol>
          <li>Create a document</li>
          <li>Write and save it as private</li>
          <li>Enjoy your distraction-free writing</li>
        </ol>
        <h1>🦄 Cool little features</h1>
        <ul>
          <li>Live writing parties 🎉</li>
          <li>Dark/Light mode 🌕</li>
          <li>Full text editor 🖋</li>
          <li>Focus mode 🧘‍♀️</li>
          <li>Private documents 🔒</li>
          <li>Document sharing 📄</li>
          <li>Little inspirational quotes ❤️</li>
          <li>Filtering/Sorting/Paging 💪</li>
        </ul>
      </div>
      <div className="flex items-center flex-col text-secondaryDark dark:text-gray-200 mt-3">
        <div>Free to use and made with love. ❤️</div>
        <div>
          Found a bug? Have an idea? Hit me up{" "}
          <a href="emailto:david@vangucht.dev" target="_blank" rel="noreferrer">
            david@vangucht.dev
          </a>
          !
        </div>
        <a
          href="https://www.buymeacoffee.com/dovydasvangucht"
          target="_blank"
          rel="noreferrer"
          className="mt-1"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            height="40px"
            width="145px"
          />
        </a>
      </div>
    </>
  );
}
